<template>
  <UiPopup
    :model-value="modelValue"
    :title="$t('assign_lead', selectedLeads.length)"
    :description="$t('assign_lead_select_user', selectedLeads.length)"
    :primary-button-text="retrieving ? '' : $t('assign_lead', selectedLeads.length)"
    :secondary-button-text="retrieving ? '' : $t('close')"
    :loading="loading || (fromQueue && Array.isArray(checkedIds) && !checkedIds?.length)"
    size="small"
    @cancel="close"
    @update:model-value="emits('update:modelValue', false)"
    @confirm="submit"
  >
    <div id="assign-lead-popup" class="transition-all duration-200">
      <UiLoader v-if="retrieving" class="mt-4" />
      <form v-else ref="form" @submit.prevent>
        <div class="mt-2 flex flex-col gap-6">
          <div class="flex flex-col">
            <UiInputMenu
              v-model="data.agentId"
              :items="usersItems"
              name="Users"
              placeholder="Search"
              input-placeholder="Select user"
              class="mb-4"
              search
              group
              avatar
              :divider="false"
              :disabled="!!userId"
              :error="useGetFieldErrors(v$, ['agentId'])"
            />
            <UiInputCheckbox
              v-model="data.reassignActivities"
              name="reassignActivities"
              label="Re-assign activities to the user as well"
            />
          </div>
          <div class="flex flex-col gap-2">
            <span>Write a note and it'll be saved in Lead timeline.</span>
            <UiInputTextField v-model="data.note" name="Name" placeholder="Optional note to Timeline" />
          </div>
          <div v-if="showMarkQualified" class="mt-2 flex flex-col gap-2 rounded-lg bg-primary-05 p-3">
            <p class="text-subhead-4 text-left text-primary-80">
              This lead wasn’t Qualified yet. Do you want to mark it as Qualified before assignment?
            </p>
            <UiInputCheckbox v-model="markQualified" name="markQualified" label="Mark lead as Qualified" />
          </div>
          <div v-if="fromQueue && checkedIds" class="mt-2 flex flex-col gap-2 rounded-lg bg-secondary-10 p-3 text-left">
            <p class="text-subhead-4 text-additional-3-100">
              {{ warnTitle ? warnTitle : 'Oops! Someone already picked up the leads ' }} ({{
                selectedIds.length - checkedIds.length
              }}).
            </p>
            <small
              >They have been automatically removed from your selection, as they are now assigned to other users. The
              remaining leads ({{ checkedIds.length }}) will be assigned to the user you selected.</small
            >
          </div>
        </div>
      </form>
    </div>
  </UiPopup>
</template>

<script setup lang="ts">
import { useVuelidate } from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import type { InputItem, Lead, Tag } from '@/types'
import { useUiStore } from '~/store/ui'
import { useAuthStore } from '~/store/auth'
import { TAGS } from '~/constants'

const emits = defineEmits(['update:modelValue', 'input'])

const uiStore = useUiStore()
const authStore = useAuthStore()

type Props = {
  modelValue: boolean
  selectedLeads: Lead[]
  userId?: number
  qualifying?: boolean
  fromQueue?: boolean
  warnTitle?: string
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
  userId: undefined,
  qualifying: false,
  fromQueue: false,
  warnTitle: '',
})
const usersItems = ref<InputItem[]>([])
const retrieving = ref(true)

onNuxtReady(async () => {
  retrieving.value = true
  usersItems.value = await useGetAllUsersItems(true)
  retrieving.value = false
})

const loading = ref(false)
const data = ref({
  agentId: props.userId || '',
  stageId: '',
  note: '',
  reassignActivities: false,
})

const markQualified = ref(false)

const selectedIds = ref<number[]>(props.selectedLeads.map((l: Lead) => l.id))
const checkedIds = ref<number[] | undefined>(undefined)

const showMarkQualified = computed(() => {
  return (
    authStore.getIsSdr &&
    !props.qualifying &&
    props.selectedLeads.length === 1 &&
    !props.selectedLeads[0].tags?.some((t: Tag) => t.code === TAGS.HIGH_QUALITY_LEAD)
  )
})

const rules = computed(() => ({
  agentId: { required: helpers.withMessage('The agent is required', required) },
}))

const v$ = useVuelidate(rules, data)

const close = () => {
  emits('update:modelValue', false)
}
const submit = async () => {
  const isValid = await v$.value.$validate()
  if (!isValid) return
  try {
    loading.value = true
    if (props.qualifying || markQualified.value) {
      // if user is qualifying, there's only one lead in the array
      await useQualifyLead(data.value.agentId, props.selectedLeads[0].id, data.value.note)
    } else {
      let leadIds = selectedIds.value
      // if user is from queue, need additional checking on selected leads
      if (props.fromQueue) {
        if (checkedIds.value?.length) {
          leadIds = selectedIds.value.filter((leadId: number) => checkedIds.value?.includes(leadId))
        }
        const data = await useCheckBulkActionInQueue('assign', leadIds)
        if (data.lead_ids.length !== leadIds.length) {
          selectedIds.value = leadIds
          checkedIds.value = data.lead_ids
          return
        }
      }
      await useAssignLeadsToAgent(
        data.value.agentId,
        leadIds,
        data.value.reassignActivities,
        data.value.note,
        props.fromQueue ? true : undefined
      )
    }
    emits(
      'input',
      usersItems.value.find((u: InputItem) => u.value === data.value.agentId)
    )
    emits('update:modelValue', false)
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  } finally {
    loading.value = false
  }
}
</script>

<style scoped></style>
